import style from "styles/play.module.css"
import {memo, MutableRefObject, useEffect, useRef} from "react"
import LinksBlock from "../../../linksBlock"
import {useState} from "react"
import useLocale from '../../../../hooks/useLocale'
import Loader from '../../../loader'

const Play = () => {
	const {playSection} = useLocale()
	const [muted, setMuted] = useState(true)
	const [loaded, setLoaded] = useState(0)

	const videoRef = useRef() as MutableRefObject<HTMLVideoElement>

	const progressSetter = () => {
		setLoaded(100)

		videoRef.current.play()
	}

	useEffect(() => {
		if (videoRef.current) {
			if (videoRef.current.readyState > 3) {
				progressSetter();
			}
			videoRef.current.addEventListener("canplaythrough", progressSetter)
		}

		return () => videoRef?.current?.removeEventListener("canplaythrough", progressSetter)
	}, [videoRef.current])

	return (
		<div id='firstScreen' className={style.play}>
			<div className={style.container}>
				<video playsInline={true} preload="auto" ref={videoRef} poster="/image/screenshotPreview.webp" src="/video/main.mp4" muted={muted} autoPlay={true} loop={true} className={style.video}/>

				<div className="absolute top-[50%] md:top-[80%] left-[50%] translate-x-[-50%] h-[3px] w-full max-w-[212px]">
					<Loader progress={loaded} animate/>
				</div>
				<div className={style.play__block}>
					{/* <h3 className={style.play__block__blockTitle__secondText}>{playSection.openWorld}</h3>*/} 
					<h3 className={style.play__block__blockTitle__secondText}></h3>
					<div className={style.play_block_sound} onClick={() => {setMuted(prev => !prev)}}>
						<p>{playSection.sound} {muted ? playSection.off : playSection.on}</p>
					</div>
					<LinksBlock isFooter={false}/>
				</div>
			</div>
		</div>
	)
}

export default memo(Play)
