import React, {FC} from 'react'
import style from '../styles/loader.module.css'

type LoaderProps = {
	progress: number
	animate?: boolean

}

const Loader:FC<LoaderProps> = ({progress, animate}) => {
	return (
		<div style={{display: progress === 100 ? "none" : "block"}} className={style.container}>
			<div style={{ width: `${progress}%` }} className={[style.progress, animate ? style.animate : ""].join(" ")}/>
		</div>
	)
}

export default Loader
